import { Web3Provider } from '@ethersproject/providers';
import {
    ERC721TokenType,
    FeeType,
    getProvider,
    ImmutableMethodResults,
    ImmutableXClient,
    LocalStorageKeys,
} from '@imtbl/imx-sdk';
import { formatUnits } from 'ethers/lib/utils';
import { constant, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

import { ProvidersConfig } from '../../utils/getProvidersConfig';
import { sendAnalytics } from '../analytics/send-analytics';
import { FlowEventName, FlowEventProps, ScreenEventName } from '../analytics/types';
import { createFlowEvent, createScreenEvent, isStorageValid } from '../analytics/utils';
import { LinkClientConfig, LinkClientConfigTS, NotifyMethod, TokenWithAmount, TokenWithDetails } from '../types';

interface IBuyWithRiskAssessment {
    config: LinkClientConfigTS;
    provider: Web3Provider;
    order: ImmutableMethodResults.ImmutableGetOrderV3Result;
    tokenSell: TokenWithDetails;
    tokenBuy: TokenWithAmount;
    fees: FeeType[];
    enableSendProviderPrefWithAssetPurchase?: boolean;
    riskAssessed: boolean;
}

export function triggerAnalyticsRequest({
    order,
    tokenSell,
    enableSendProviderPrefWithAssetPurchase,
    riskAssessed,
}: Pick<IBuyWithRiskAssessment, 'order' | 'tokenSell' | 'enableSendProviderPrefWithAssetPurchase' | 'riskAssessed'>) {
    const { quantity, asset } = tokenSell;

    const makerTakerFees = order.sell.type === ERC721TokenType.ERC721 ? order.taker_fees : order.maker_fees;
    const nftQuantity = order.sell.type === ERC721TokenType.ERC721 ? order.sell.data.quantity : order.buy.data.quantity;

    const salePrice = formatUnits(makerTakerFees.quantity_with_fees, makerTakerFees.decimals);
    const revenue = formatUnits(nftQuantity.mul(makerTakerFees.quantity_with_fees), makerTakerFees.decimals);

    let createFlowEventProps = {
        orderId: order.order_id.toString(),
        assetId: asset?.token_id,
        salePrice,
        riskAssessed,
        provider: 'redacted',
    } as FlowEventProps;
    if (enableSendProviderPrefWithAssetPurchase) {
        let provider = 'not_found';
        if (isStorageValid(localStorage)) {
            provider = localStorage.getItem(LocalStorageKeys.PROVIDER_PREFERENCE) || '';
        }
        createFlowEventProps = {
            ...createFlowEventProps,
            provider,
        };
    }
    const event = {
        // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
        ...createFlowEvent(FlowEventName.assetBuyNowSucceeded, createFlowEventProps),

        quantity: quantity.toNumber(),
        revenue: parseFloat(revenue),
        price: parseFloat(salePrice),
    };
    sendAnalytics(createScreenEvent(ScreenEventName.buyNowConfirmedOpened), event);
}

export function buy(
    config: LinkClientConfig | LinkClientConfigTS,
    order: ImmutableMethodResults.ImmutableGetOrderV3Result,
    tokenSell: TokenWithDetails,
    tokenBuy: TokenWithAmount,
    notify: NotifyMethod,
    providerOptions: ProvidersConfig,
): TE.TaskEither<Error, number> {
    return pipe(
        TE.fromIO<Error, void>(() => notify({ msg: 'Connecting wallet' })),
        TE.chain(() => TE.bindTo('provider')(getProvider(providerOptions))),
        TE.bind('client', ({ provider }) =>
            ImmutableXClient.buildF({
                ...config,
                signer: provider.getSigner(),
            }),
        ),
        TE.chainFirst(constant(TE.fromIO(() => notify({ msg: 'Purchasing order' })))),
        TE.bind('transfer', ({ client, client: { address } }) =>
            client.createTradeV3F({
                user: address,
                orderId: order.order_id,
                fees: [],
            }),
        ),
        TE.chainFirst(() =>
            TE.fromIO(() => {
                triggerAnalyticsRequest({
                    order,
                    tokenSell,
                    riskAssessed: false,
                });
            }),
        ),
        TE.map(() => order.order_id),
    );
}

export function buyV3(
    client: ImmutableXClient,
    order: ImmutableMethodResults.ImmutableGetOrderV3Result,
    tokenSell: TokenWithDetails,
    notify: NotifyMethod,
    fees: FeeType[],
    enableSendProviderPrefWithAssetPurchase?: boolean,
): TE.TaskEither<Error, number> {
    return pipe(
        TE.fromIO<Error, void>(() => notify({ msg: 'Purchasing order' })),
        TE.bind('transfer', () => {
            return client.createTradeV3F({
                user: client.address,
                orderId: order.order_id,
                fees,
            });
        }),
        TE.chainFirst(() =>
            TE.fromIO(() => {
                triggerAnalyticsRequest({
                    order,
                    tokenSell,
                    enableSendProviderPrefWithAssetPurchase,
                    riskAssessed: false,
                });
            }),
        ),
        TE.map(() => order.order_id),
    );
}
